import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiService } from './api.service';
import { Profile } from '../models/profile.model';
import {
  SocialMedia, ContactInfoFactory, UserRoles, User,
} from '../models';
import { ProfileForm } from '../forms';
import { UpdateProfileComplete } from '../state-mgmt/profile/profile.actions';
import { NotificationEventTypes, NotificationEvent } from '../notifications/notification-event';
import { NotificationEventService } from '../notifications/notification-event.service';
import { PromptDialogService } from './prompt-dialog.service';
import { UserProfileUpdated } from '../state-mgmt';

interface DialogFields {
  title: string;
  description: string;
  buttonText: string
}

export interface ProfileGuardResult {
  isComplete: boolean;
  redirectUrl: string;
}

const PROFILE_INFO_DIALOG : DialogFields = {
  title: 'Your profile(s) is incomplete',
  description: 'Your Office information received from Dash/Trident is incomplete, and can only be modified there. '
               + 'Please contact your office administrator for assistance',
  buttonText: 'View Profile',
};
/**
 * Client side service for interacting with the server to retrieve and update profiles.
 */
@Injectable()
export class ProfileService {
  resource = 'profiles';
  addDelegateEndpoint = 'add-delegate';
  removeDelegateEndpoint = 'remove-delegate';

  constructor(
    private store: Store<any>,
    private apiService: ApiService,
    private notificationService: NotificationEventService,
    private dialogService: PromptDialogService,
  ) {
  }

  /**
   * Update an existing profile with
   *
   * @param id
   * @param profile
   * @return an Observable that results in a Profile
   */
  updateProfile(id: string, profile: Profile): Observable<Profile> {
    const clonedProfile = { ...profile };
    delete clonedProfile.audit;
    return this.apiService.put(`profiles/${id}`, clonedProfile).pipe(map((body) => new Profile(body)));
  }

  addDelegate(fromUser: string, toUser: string): Observable<User> {
    const payload = {
      fromUser,
      toUser,
    };
    return this.apiService.post(`${this.resource}/${this.addDelegateEndpoint}`, payload).pipe(map((body) => new User(body)));
  }

  removeDelegate(fromUser: string, toUser: string): Observable<User> {
    const payload = {
      fromUser,
      toUser,
    };
    return this.apiService.post(`${this.resource}/${this.removeDelegateEndpoint}`, payload).pipe(
      map((body) => new User(body)),
    );
  }

  updateProfilePartial(profile: Profile, fields?: string[]): Observable<Profile> {
    let payload: any;

    payload = profile;
    if (fields && fields.length) {
      payload = {};
      // order.orderState = order.listing.orderState;
      fields.forEach((field) => {
        payload[field] = profile[field];
      });
    }
    delete payload.audit;
    return this.apiService.put(`${this.resource}/${profile._id}`, payload).pipe(
      map((body) => new Profile(body)),
    );
  }

  async patch(profileForm: ProfileForm, isCurrentUser?: boolean, isVipProfileUpdate?: boolean): Promise<Profile> {
    // Gets all the dirty fields that have changed
    const dirty = profileForm.getDirty();
    dirty.contactInfo = this.getSocialMedia(profileForm.originalValue as Profile, profileForm.value);
    if (profileForm.value.phoneNumbers?.length && !profileForm.value.phoneNumbers[0].primary) {
      profileForm.value.phoneNumbers.forEach((phoneNum, ind) => {
        if (ind === 0) {
          phoneNum.primary = true;
        } else {
          phoneNum.primary = false;
        }
      });
      dirty.phoneNumbers = profileForm.value.phoneNumbers;
    }
    if (dirty.contactInfo?.length === 0) {
      dirty.contactInfo = [];
    }
    const updatedProfile = await this.apiService.put(`${this.resource}/${profileForm.id}`, dirty).pipe(
      map((body) => new Profile(body)),
      catchError((error) => {
        // Display toaster error
        console.error('There was an error patching the profile values', error, dirty);
        const event = new NotificationEvent(NotificationEventTypes.ERROR, 'An error occured updating your profile');
        this.notificationService.getEventEmitter().emit(event);
        return of(null);
      }),
    ).toPromise();

    if (updatedProfile) {
      this.store.dispatch(UpdateProfileComplete({ payload: updatedProfile }));
      if (isCurrentUser) {
        // If we are updating the current user, emit an event to update the profile
        // display information
        this.store.dispatch(UserProfileUpdated({ payload: updatedProfile }));
      }
    }
    return updatedProfile;
  }

  getSocialMedia(profile: Profile, socialMedia: SocialMedia) {
    const getSocialMediaType = ContactInfoFactory.getContactInfoSocialMediaFactory(socialMedia);
    const getProfileAddress = profile.contactInfo.filter((item) => item.type === 'card');
    return getProfileAddress?.length ? [...getProfileAddress, ...getSocialMediaType] : getSocialMediaType;
  }

  /**
   * Get a profile by id
   *
   * @param id the profile id
   */
  getProfile(id: string): Observable<Profile> {
    console.log('Get profile for id = %s', id);
    return this.apiService.get(`${this.resource}/${id}`).pipe(map((body) => new Profile(body)));
  }

  static unsatisfiedRules(profile: Profile): string[] {
    // Check any validation rules specific to the profile
    const unsatisfiedRules = [];
    // Only one rule for now
    if (profile?.rules.find((r) => (r === 'requireOfficePhone')) && !profile?.office?.phoneNumber?.number) {
      unsatisfiedRules.push("Your profile must have a phone number of type 'Office'.");
    }
    if (profile.isHawaiianAgent && profile.realtorType == null) {
      unsatisfiedRules.push('Your profile requires a realtor type to be set for Hawaii regulations.');
    }
    return unsatisfiedRules;
  }

  /**
   * Check the profile to see if it complete.
   *
   * If it is complete return a ProfileGuardResult with isComplete = true
   *
   * If it is not complete, prompt the user to see if they want to go to their
   * profile page. Then return a ProfileGuardResult with isComplete = false and
   * a redirectUrl for the user's profile page if the answer yes to the prompt.
   *
   * @param profile
   */
  async guardAgainstIncompleteProfile(profiles: Profile[], bypassProfileStatus: boolean, primaryProfile: Profile): Promise<ProfileGuardResult> {
    const isProfileComplete = profiles.find((p) => p?.status?.isComplete && p?.realEstateRole === UserRoles.AGENT && p.offices?.length);
    const unsatisfiedRules: string[] = isProfileComplete ? [] : ProfileService.unsatisfiedRules(primaryProfile);

    // If feature flag is set to ignore office validation, then it is automatically valid.
    // Otherwise look at office details and outcome of rule evaluation.
    const isProfileHasOfficeInformation = bypassProfileStatus ? true : isProfileComplete;

    const dialogFields = { ...PROFILE_INFO_DIALOG };

    if (isProfileHasOfficeInformation) {
      // profile is complete, MDM data is complete, no need to stop the progress
      return { isComplete: true, redirectUrl: null };
    }
    // profile is not complete so user must be prompted for a decision
    // the dialog description is different if the office information is not complete
    dialogFields.description = isProfileHasOfficeInformation
      ? 'Before placing a new order, please address the missing profile information'
      : this.buildUnsatisfiedRulesDescription(unsatisfiedRules);

    return dialogFields
      ? { isComplete: false, redirectUrl: await this.prompt(dialogFields, primaryProfile) }
      : { isComplete: true, redirectUrl: null };
  }

  /**
   * Build a description string for the dialog that lists the rules that have not been met.
   *
   * @param unsatisfiedRules
   * @private
   */
  private buildUnsatisfiedRulesDescription(unsatisfiedRules: string[]) {
    let description = 'Before placing an order, please address the missing information in your profile. If the missing information pertains to your office information that is received from Dash/Trident, please contact your office administrator for assistance. Otherwise please complete all the required fields within your profile.';
    if (unsatisfiedRules?.length) {
      description += ` (${unsatisfiedRules.join(' ')})`;
    }
    return description;
  }

  /**
   * If this is called the user cannot navigate. They are being prompted to see if they want to view
   * their profile. If they choose to do so the profile page url is returned, otherwise null is returned
   *
   * @param dialogFields
   * @param profile
   */
  async prompt(dialogFields: DialogFields, profile): Promise<string> {
    const dialogResponse = await this.dialogService.openPrompt(
      dialogFields.title,
      dialogFields.description,
      dialogFields.buttonText,
    );
    return (dialogResponse?.text === dialogFields.buttonText) ? `/my-profile/${profile._id}` : null;
  }
}
