<div class="rich-text-container" (lcClickOutside)="onClickedOutside()">
  <div
    class="lc-rich-text html-enabled"
    [ngClass]="{'text-fits': textFits, 'text-overflow': !textFits}"
  >
    <div id="scrolling-container">
      <lc-editor-control
        [format]="format"
        [richTextEnabled]="richTextEnabled"
        [toolbarEnabled]="toolbarEnabled"
        [setBorder]="isRichTextActions"
        [minimumHeight]="minimumHeight"
        [isHeader]="isHeader"
        [isContact]="isContact"
        [formControl]="formControl"
        (change)="onTextChange($event)"
        [errorState]="hasErrors"
        [disabled]="readonly"
        [statistics]="richTextStats"
        (selectionChange)="onBlur()"
        (undoAiGeneration)="onUndo()"
        [undoDisabled]="undoDisabled"
        [showUndo]="['102'].includes(productCode) || (!['107', '112'].includes(productCode) && bodyIndex === 0)"
      ></lc-editor-control>
    </div>
    <div *ngIf="isRichTextActions">
      <div class="editor-actions-btn p-1 fx-row y-center" [ngClass]="(aiFeatureIsEnabled$ | async) ? 'x-space-between' : 'x-end'">
        <div *ngIf="aiFeatureIsEnabled$ | async" class="fx-column">
          <p *ngIf="propertyDescriptionService.getAiMaxRequests(ai) as aiMaxRequests" style="margin: 0; font-size: x-small;">
            AI Usage: {{ propertyDescriptionService.getAiRequests(ai) }} / {{ aiMaxRequests ?? 0 }}
          </p>
          <p *ngIf="!['107', '112'].includes(productCode) && bodyIndex === 0" class="mat-hint" style="margin: 0; font-size: x-small;">
            AI generated content may contain errors. Verify for accuracy.
          </p>
        </div>
        <div class="fx-row fx-gap-xs">
          <button
            id="btn-cancel"
            mat-raised-button
            (click)="onCancel()"
          >Cancel
          </button>
          <button
            id="btn-save"
            mat-raised-button
            [color]="((aiFeatureIsEnabled$ | async) === false || bodyIndex !== 0 || productCode === ProductCode.CLIENT_REPORT) && 'primary'"
            [disabled]="disableSave || !textFits"
            (click)="onSave()"
          >Save
          </button>
          <button
            *ngIf="(aiFeatureIsEnabled$ | async) && bodyIndex === 0 && productCode !== ProductCode.CLIENT_REPORT"
            id="btn-generate"
            mat-raised-button
            color="primary"
            (click)="onGenerate()"
          >Regenerate
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
